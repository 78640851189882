var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : [
              _c(
                "div",
                { staticClass: "h-mb-40" },
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var handleSubmit = ref.handleSubmit
                          var invalid = ref.invalid
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "container container--fullWidth grid grid__col-small-big",
                              },
                              [
                                _c("div", { staticClass: "h-margin-30" }, [
                                  _c("h2", [_vm._v("Password")]),
                                  _c("p", [
                                    _vm._v(
                                      "Your email address is your identity on TheCharts and is used to log in."
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "wrapper--filled" }, [
                                  _c(
                                    "form",
                                    {
                                      staticClass: "form",
                                      attrs: { autocomplete: "off" },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          handleSubmit(
                                            _vm.validateForm(
                                              "formChangePassword"
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Current Password",
                                              rules: { required: true },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "formLabel",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Current Password"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .passwordChangeParams
                                                                .old_password,
                                                            expression:
                                                              "passwordChangeParams.old_password",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          type: "password",
                                                          placeholder:
                                                            "Current Password",
                                                          name: "old password",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .passwordChangeParams
                                                              .old_password,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.passwordChangeParams,
                                                              "old_password",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "New Password",
                                              rules: { required: true, min: 8 },
                                              vid: "new password1",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "formLabel",
                                                        },
                                                        [_vm._v("New Password")]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .passwordChangeParams
                                                                .new_password1,
                                                            expression:
                                                              "passwordChangeParams.new_password1",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          type: "password",
                                                          placeholder:
                                                            "Type New Password",
                                                          name: "new password1",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .passwordChangeParams
                                                              .new_password1,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.passwordChangeParams,
                                                              "new_password1",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: errors[0],
                                                              expression:
                                                                "errors[0]",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Password must be at least 8 characters"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "Confirm New Password",
                                              rules: {
                                                required: true,
                                                min: 8,
                                                confirmed: "new password1",
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "formLabel",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Confirm New Password"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .passwordChangeParams
                                                                .new_password2,
                                                            expression:
                                                              "passwordChangeParams.new_password2",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "new password2",
                                                          type: "password",
                                                          placeholder:
                                                            "Confirm New Password",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .passwordChangeParams
                                                              .new_password2,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.passwordChangeParams,
                                                              "new_password2",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: errors[0],
                                                              expression:
                                                                "errors[0]",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Password1 and Password2 must be the same"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.passwordChangeError
                                        ? _c(
                                            "p",
                                            { staticClass: "form__error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.passwordChangeError)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "form__submit" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn--primary btn--sm",
                                              class: { isDisabled: invalid },
                                            },
                                            [_vm._v("Change Password")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var handleSubmit = ref.handleSubmit
                      var invalid = ref.invalid
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "container container--fullWidth grid grid__col-small-big",
                          },
                          [
                            _c("div", { staticClass: "h-margin-30" }, [
                              _c("h2", [_vm._v("Profile")]),
                              _c("p", [
                                _vm._v(
                                  "Your email address is your identity on TheCharts and is used to log in."
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "wrapper--filled" }, [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "formGroup" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Display Name",
                                        rules: { required: true },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  { staticClass: "formLabel" },
                                                  [_vm._v("Display Name")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.authenticatedUser
                                                          .display_name,
                                                      expression:
                                                        "authenticatedUser.display_name",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "display name",
                                                    type: "text",
                                                    placeholder:
                                                      "Enter Producer Name",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.authenticatedUser
                                                        .display_name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.authenticatedUser,
                                                        "display_name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "formGroup" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Email Address",
                                        rules: { required: true, email: true },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  { staticClass: "formLabel" },
                                                  [_vm._v("Email Address")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.authenticatedUser
                                                          .email,
                                                      expression:
                                                        "authenticatedUser.email",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "email",
                                                    type: "text",
                                                    placeholder:
                                                      "Enter Email Address",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.authenticatedUser
                                                        .email,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.authenticatedUser,
                                                        "email",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(errors[0]) + " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "container container--fullWidth grid grid__col-small-big",
                            attrs: { id: "producer-page" },
                          },
                          [
                            _c("div", { staticClass: "h-margin-30" }, [
                              _c("h2", [_vm._v("Producer Page")]),
                              _c("p", [
                                _vm._v(
                                  "Upload your profile & background pictures and give a custom look to your producer page."
                                ),
                              ]),
                              _c("br"),
                              _vm.authenticatedUser
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn btn--sm",
                                      attrs: {
                                        href:
                                          "https://clickandrap.com/producer/" +
                                          (_vm.authenticatedUser
                                            ? _vm.authenticatedUser.slug
                                            : ""),
                                        target: "blank",
                                      },
                                    },
                                    [_vm._v("View Profile")]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "wrapper--filled grid grid__col2",
                              },
                              [
                                _c("div", [
                                  _c("div", { staticClass: "formGroup" }, [
                                    _c(
                                      "h4",
                                      {
                                        staticClass: "form__title h-textCenter",
                                      },
                                      [_vm._v("Logo Image")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "formUpload formUpload--square h-rounded",
                                        class: {
                                          "formUpload--empty":
                                            !_vm.authenticatedUser
                                              .image_logo_url,
                                          "formUpload--uploaded":
                                            _vm.authenticatedUser
                                              .image_logo_url,
                                        },
                                      },
                                      [
                                        _c("icon", {
                                          staticClass: "formUpload__icon",
                                          attrs: { name: "camera" },
                                        }),
                                        _vm.authenticatedUser.image_logo_url
                                          ? _c("img", {
                                              staticClass:
                                                "formUpload__image h-rounded",
                                              attrs: {
                                                src: _vm.authenticatedUser
                                                  .image_logo_url,
                                              },
                                            })
                                          : _vm._e(),
                                        _c("input", {
                                          staticClass: "formUpload__inputHide",
                                          attrs: { type: "file" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.uploadFile(
                                                $event.target.files,
                                                _vm.FileToRemoveOrAdd.Logo
                                              )
                                            },
                                          },
                                        }),
                                        _vm.authenticatedUser.image_logo_url
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn btn--danger btn--sm formUpload__btnDelete",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      _vm.FileToRemoveOrAdd.Logo
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Remove")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c("div", [
                                  _c("div", { staticClass: "formGroup" }, [
                                    _c(
                                      "h4",
                                      {
                                        staticClass: "form__title h-textCenter",
                                      },
                                      [_vm._v("Background Image")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "formUpload formUpload--lg",
                                        class: {
                                          "formUpload--empty":
                                            !_vm.authenticatedUser
                                              .image_background_url,
                                          "formUpload--uploaded":
                                            _vm.authenticatedUser
                                              .image_background_url,
                                        },
                                      },
                                      [
                                        _c("icon", {
                                          staticClass: "formUpload__icon",
                                          attrs: { name: "camera" },
                                        }),
                                        _vm.authenticatedUser
                                          .image_background_url
                                          ? _c("img", {
                                              staticClass: "formUpload__image",
                                              attrs: {
                                                src: _vm.authenticatedUser
                                                  .image_background_url,
                                              },
                                            })
                                          : _vm._e(),
                                        _c("input", {
                                          staticClass: "formUpload__inputHide",
                                          attrs: { type: "file" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.uploadFile(
                                                $event.target.files,
                                                _vm.FileToRemoveOrAdd.Background
                                              )
                                            },
                                          },
                                        }),
                                        _vm.authenticatedUser
                                          .image_background_url
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn btn--danger btn--sm formUpload__btnDelete",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFile(
                                                      _vm.FileToRemoveOrAdd
                                                        .Background
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Remove")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "container container--fullWidth grid grid__col-small-big",
                            attrs: { id: "producer-page" },
                          },
                          [
                            _c("div", { staticClass: "h-margin-30" }, [
                              _c("h2", [_vm._v("Biography")]),
                              _c("p", [
                                _vm._v(
                                  "Add details about you and upload an EPK for users to download in your Elite page."
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "wrapper--filled" }, [
                              _c("div", { staticClass: "formGroup" }, [
                                _c("label", { staticClass: "formLabel" }, [
                                  _vm._v("Biography"),
                                ]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.authenticatedUser.bio,
                                      expression: "authenticatedUser.bio",
                                    },
                                  ],
                                  staticClass:
                                    "formInput formInput--textarea formInput--fullWidth",
                                  attrs: {
                                    type: "text",
                                    rows: "4",
                                    placeholder: "Enter Elite page description",
                                  },
                                  domProps: {
                                    value: _vm.authenticatedUser.bio,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.authenticatedUser,
                                        "bio",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "formGroup" }, [
                                _c(
                                  "p",
                                  { staticClass: "textSizeSm p--lightGrey" },
                                  [_vm._v("EPK file")]
                                ),
                                !_vm.authenticatedUser.about_epk_url
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "formUpload formUpload--sm formUpload--empty",
                                      },
                                      [
                                        _c("icon", {
                                          staticClass: "formUpload__icon",
                                          attrs: { name: "file" },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "formUpload__labelDropzone",
                                          },
                                          [_vm._v("Drop file here")]
                                        ),
                                        _c("input", {
                                          staticClass: "formUpload__inputHide",
                                          attrs: { type: "file" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.uploadFile(
                                                $event.target.files,
                                                _vm.FileToRemoveOrAdd.Epk
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.authenticatedUser.about_epk_url
                                  ? _c(
                                      "div",
                                      { staticClass: "flexbox h-mt-10" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn--sm btn--danger btn--border",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(
                                                  _vm.FileToRemoveOrAdd.Epk
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Remove EPK File")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "container container--fullWidth grid grid__col-small-big",
                            attrs: { id: "producer-page" },
                          },
                          [
                            _c("div", { staticClass: "h-margin-30" }, [
                              _c("h2", [_vm._v("Social Accounts")]),
                              _c("p", [
                                _vm._v(
                                  "Link your social accounts so that people can follow you."
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "wrapper--filled" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Facebook URL",
                                    rules: { regex: _vm.regexUrl },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "formGroup" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "formLabel" },
                                                  [_vm._v("Facebook")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.authenticatedUser
                                                          .facebook,
                                                      expression:
                                                        "authenticatedUser.facebook",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "facebook",
                                                    type: "text",
                                                    placeholder:
                                                      "https://facebook.com/",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.authenticatedUser
                                                        .facebook,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.authenticatedUser,
                                                        "facebook",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Twitter URL",
                                    rules: { regex: _vm.regexUrl },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "formGroup" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "formLabel" },
                                                  [_vm._v("Twitter")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.authenticatedUser
                                                          .twitter,
                                                      expression:
                                                        "authenticatedUser.twitter",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "twitter",
                                                    type: "text",
                                                    placeholder:
                                                      "https://twitter.com/",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.authenticatedUser
                                                        .twitter,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.authenticatedUser,
                                                        "twitter",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Instagram URL",
                                    rules: { regex: _vm.regexUrl },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "formGroup" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "formLabel" },
                                                  [_vm._v("Instagram")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.authenticatedUser
                                                          .instagram,
                                                      expression:
                                                        "authenticatedUser.instagram",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "instagram",
                                                    type: "text",
                                                    placeholder:
                                                      "https://instagram.com/",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.authenticatedUser
                                                        .instagram,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.authenticatedUser,
                                                        "instagram",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "YouTube URL",
                                    rules: { regex: _vm.regexUrl },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "formGroup" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "formLabel" },
                                                  [_vm._v("YouTube")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.authenticatedUser
                                                          .youtube,
                                                      expression:
                                                        "authenticatedUser.youtube",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "youtube",
                                                    type: "text",
                                                    placeholder:
                                                      "https://youtube.com/",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.authenticatedUser
                                                        .youtube,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.authenticatedUser,
                                                        "youtube",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Soundcloud URL",
                                    rules: { regex: _vm.regexUrl },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "formGroup" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "formLabel" },
                                                  [_vm._v("Soundcloud")]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.authenticatedUser
                                                          .soundcloud,
                                                      expression:
                                                        "authenticatedUser.soundcloud",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "soundcloud",
                                                    type: "text",
                                                    placeholder:
                                                      "https://soundcloud.com/",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.authenticatedUser
                                                        .soundcloud,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.authenticatedUser,
                                                        "soundcloud",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "container container--fullWidth grid grid__col-small-big",
                            attrs: { id: "downloads" },
                          },
                          [
                            _c("div", { staticClass: "h-margin-30" }, [
                              _c("h2", [_vm._v("Free Demo Downloads")]),
                              _c("p", [
                                _vm._v(
                                  "Decide how to manage your free demo downloads."
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "wrapper--filled" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "formGroup formGroup--horizontal h-alignCenter",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "formLabel formLabel--dark",
                                    },
                                    [
                                      _vm._v(
                                        "DEFAULT - ALLOW TAGGED DEMO DOWNLOADS"
                                      ),
                                    ]
                                  ),
                                  _c("toggle-button", {
                                    staticClass: "vue-js-switch--margin",
                                    attrs: { color: "#0269FF", labels: true },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateDemoDownloadAllowed(
                                          _vm.authenticatedUser
                                            .demo_download_allowed
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.authenticatedUser
                                          .demo_download_allowed,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.authenticatedUser,
                                          "demo_download_allowed",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "authenticatedUser.demo_download_allowed",
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "textSizeSm p--lightGrey h-textJustify",
                                      staticStyle: { "margin-top": "8px" },
                                    },
                                    [
                                      _vm._v(
                                        "Set a default for your future uploads, you can always override this setting on the individual beats. If you don't upload a tag, we will use TheCharts default tag."
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("hr", { staticClass: "form__divider" }),
                              _c("div", { staticClass: "formGroup" }, [
                                _c(
                                  "p",
                                  { staticClass: "textSizeSm p--lightGrey" },
                                  [
                                    _vm._v(
                                      "Audio file with tags (make it 5-6 min. long with tags throughout)"
                                    ),
                                  ]
                                ),
                                !_vm.authenticatedUser.tag_file
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "formUpload formUpload--sm formUpload--empty",
                                      },
                                      [
                                        _c("icon", {
                                          staticClass: "formUpload__icon",
                                          attrs: { name: "file" },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "formUpload__labelDropzone",
                                          },
                                          [_vm._v("Drop file here")]
                                        ),
                                        _c("input", {
                                          staticClass: "formUpload__inputHide",
                                          attrs: { type: "file" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.uploadFile(
                                                $event.target.files,
                                                _vm.FileToRemoveOrAdd.Tag
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.authenticatedUser.tag_file
                                  ? _c(
                                      "div",
                                      { staticClass: "flexbox h-mt-10" },
                                      [
                                        _c(
                                          "audio",
                                          {
                                            attrs: {
                                              controls: "",
                                              src: _vm.userTagUrl,
                                            },
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                "Your browser does not support the audio element."
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flexbox--alignRight btn btn--sm btn--danger btn--border",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(
                                                  _vm.FileToRemoveOrAdd.Tag
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Remove Tag File")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("div", { staticClass: "fabWrapper fabWrapper--right" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        _vm.handleSubmit(_vm.validateForm("user"))
                      },
                    },
                  },
                  [_vm._v("SAVE CHANGES")]
                ),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Settings")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }